import React from "react";

import SEO from "~/helpers/seo";
import Frame from "~/interface/layout/frame";
import Logo from "~/interface/identity/logo";
import Map from "~/interface/compositions/map";
import Navigation from "~/interface/compositions/navigation";
import Footer from "~/interface/compositions/footer";

const HomePage = () => {
  return (
    <Frame className="bg-black text-white ">
      <div className="w-full h-[calc(100vh-170px)] md:flex justify-between">
        <div className="flex flex-col md:flex-row gap-10 sm:gap-28 h-full px-10 sm:px-20 md:px-[128px]">
          <Navigation />

          <div className="flex items-center">
            <div className="max-w-[540px]">
              <div className="mb-6">
                <Logo className="mb-2" />

                <p className="tracking-[-0.02em]">Digital Strategy & Design</p>
              </div>

              <div>
                <h1 className="font-regular tracking-[0.02em] leading-[32px]">
                  Artefactは、「デザイナーの毎日をもっと楽しく」を目指すWebメディア
                  『
                  <a href="https://www.unprinted.design/" className="font-bold">
                    unprinted
                  </a>
                  』を運営するメディア企業です。
                </h1>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden lg:block bg-gray-darkest h-full w-2/5 relative border-l-[0.5px] border-white">
          <Map />
        </div>
      </div>

      <Footer />
    </Frame>
  );
};

export default HomePage;

export const Head = () => {
  return (
    <SEO
      title="株式会社Artefact | デジタル戦略とデザイン"
      description="株式会社Artefactは東京に拠点を置く、デジタル戦略とデザインを専門としたプロダクトスタジオです。「デジタルデザイナーの毎日をもっと楽しく」を目指してWebマガジン「unprinted」を運営しています。"
      url="/"
    />
  );
};
