import React from "react";
import cx from "classnames";

type Props = {
  className?: string;
  height?: number;
  width?: number;
  fill: string;
};

Logo.defaultProps = {
  fill: "#ffffff",
};

function Logo(props: Props) {
  return (
    <svg
      className={cx(props.className)}
      width="180"
      height="36"
      viewBox="0 0 180 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_35_497)">
        <path
          d="M0 22.8696V22.5073C0 14.4463 4.61926 10.144 9.91781 10.144C13.4955 10.144 15.624 11.6536 16.9826 14.084V10.7781H25.0889V34.4631H16.9826V30.6892C16.3244 32.0077 15.3087 33.1145 14.0514 33.8832C12.7942 34.652 11.3461 35.0517 9.87253 35.0367C4.61926 35.0065 0 31.0666 0 22.8696ZM17.209 22.7338V22.3715C17.209 18.2504 15.4881 16.2578 12.7709 16.2578C9.91781 16.2578 8.2422 18.2957 8.2422 22.4168V22.7791C8.2422 26.7643 9.9631 28.8173 12.635 28.8173C15.3069 28.8173 17.209 26.7643 17.209 22.7338Z"
          fill="white"
        />
        <path
          d="M30.3418 10.7784H38.4481V15.4883C39.9577 11.9559 42.4334 10.4161 46.1016 10.4614V18.0092C41.1653 17.8734 38.4481 19.4131 38.4481 23.2172V34.4634H30.3418V10.7784Z"
          fill="white"
        />
        <path
          d="M51.62 16.3031H48.3594V10.7781H51.62V5.8418H59.7188V10.7781H64.0664V16.3031H59.7188V34.4631H51.62V16.3031Z"
          fill="white"
        />
        <path
          d="M65.5684 22.9149V22.5526C65.5684 14.9444 71.2292 10.144 78.3996 10.144C84.8756 10.144 90.4761 13.8576 90.4761 22.3715V24.5H73.7653C73.9917 27.6248 75.8032 29.4362 78.7015 29.4362C81.3735 29.4362 82.4754 28.2135 82.8226 26.6284H90.4308C89.676 31.9723 85.5851 35.0065 78.4298 35.0065C71.0028 35.0065 65.5684 30.659 65.5684 22.9149ZM82.5509 19.8958C82.4151 17.0427 81.0414 15.4577 78.3845 15.4577C75.939 15.4577 74.2634 17.0427 73.8558 19.8958H82.5509Z"
          fill="white"
        />
        <path
          d="M95.7515 16.3033H92.7324V10.7783H95.7515V8.69507C95.7515 2.80778 99.4198 0 105.035 0C106.29 0.00414658 107.538 0.171606 108.749 0.498155V6.43073C108.029 6.21363 107.281 6.10675 106.53 6.11372C104.628 6.11372 103.813 7.15532 103.813 8.69507V10.7783H108.613V16.3033H103.813V34.4633H95.7515V16.3033Z"
          fill="white"
        />
        <path
          d="M110.334 22.8696V22.5073C110.334 14.4463 114.953 10.144 120.252 10.144C123.829 10.144 125.958 11.6536 127.317 14.084V10.7781H135.423V34.4631H127.317V30.6892C126.658 32.0077 125.643 33.1145 124.385 33.8832C123.128 34.652 121.68 35.0517 120.207 35.0367C114.953 35.0065 110.334 31.0666 110.334 22.8696ZM127.543 22.7338V22.3715C127.543 18.2504 125.822 16.2578 123.105 16.2578C120.252 16.2578 118.576 18.2957 118.576 22.4168V22.7791C118.576 26.7643 120.297 28.8173 122.969 28.8173C125.641 28.8173 127.558 26.7643 127.558 22.7338H127.543Z"
          fill="white"
        />
        <path
          d="M138.428 22.9149V22.5526C138.428 14.6727 144.089 10.144 151.063 10.144C156.859 10.144 162.203 12.6801 162.656 19.8807H155.048C154.686 17.6617 153.599 16.4389 151.274 16.4389C148.421 16.4389 146.745 18.4768 146.745 22.4772V22.8394C146.745 26.9605 148.33 29.089 151.41 29.089C153.674 29.089 155.259 27.7304 155.531 25.1491H162.822C162.596 31.1269 158.565 35.0216 150.912 35.0216C143.817 35.0065 138.428 30.8854 138.428 22.9149Z"
          fill="white"
        />
        <path
          d="M167.561 16.3031H164.301V10.7781H167.561V5.8418H175.675V10.7781H180.015V16.3031H175.675V34.4631H167.561V16.3031Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_35_497">
          <rect width="180" height="35.0067" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Logo;
